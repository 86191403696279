import React from "react";
import styles from "./AlertModal.module.css";

interface AlertModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h3>¿Querés salir?</h3>
        <p>
          No te preocupes, tu información quedará guardada en este navegador y
          podrás continuar cuando quieras.
        </p>
        <div className={styles.buttons}>
          <button onClick={onCancel} className={styles.cancelButton}>
            Cancelar
          </button>
          <button onClick={onConfirm} className={styles.confirmButton}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
};
