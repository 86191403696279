import React, { useState } from "react";
import { PersonalInfoStep } from "./PersonalInfoStep/PersonalInfoStep";
import { WorkExperienceStep } from "./WorkExperienceStep/WorkExperienceStep";
import { SkillsStep } from "./SkillsStep/SkillsStep";
import { Wizard } from "../Wizard/Wizard";
import { EducationStep } from "./EducationStep/EducationStep";
import { ReviewStep } from "./ReviewStep/ReviewStep";
import { UploadStep } from "./UploadStep/UploadStep";
import { TemplateStep, TemplateOption } from "./TemplateStep/TemplateStep";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AlertModal } from "../AlertModal/AlertModal";
import { CoursesAndMoreStep } from "./CoursesAndMoreStep/CoursesAndMoreStep";

interface GenericWorkflowCVProps {
  onExit: () => void;
  mode: "create" | "improve";
  onTemplateSelect?: (template: TemplateOption) => void;
  selectedTemplate?: TemplateOption;
  onStepValidityChange?: (isValid: boolean) => void;
}

interface StepComponentProps {
  onValidityChange?: (isValid: boolean) => void;
  onPaymentClick?: () => void;
  onTemplateSelect?: (template: TemplateOption) => void;
  selectedTemplate?: TemplateOption;
}

const CREATE_STEPS = [
  {
    title: "¡Contanos sobre vos! 😊",
    component: PersonalInfoStep,
    path: "personal-info",
  },
  {
    title: "Tu experiencia laboral 💼",
    component: WorkExperienceStep,
    path: "work-experience",
  },
  {
    title: "Tu formación académica 📚",
    component: EducationStep,
    path: "education",
  },
  {
    title: "Tus habilidades",
    component: SkillsStep,
    path: "skills",
  },
  {
    title: "Elegí un diseño ✨",
    component: ({
      onTemplateSelect,
      selectedTemplate,
      onValidityChange,
    }: StepComponentProps) => (
      <TemplateStep
        onTemplateSelect={(template) => onTemplateSelect?.(template)}
        selectedTemplate={selectedTemplate}
        showKeepCurrent={false}
        onValidityChange={(isValid) => onValidityChange?.(isValid)}
      />
    ),
    path: "template",
  },
  {
    title: "Cursos y más sobre vos",
    component: ({ onValidityChange }: StepComponentProps) => (
      <CoursesAndMoreStep onValidityChange={onValidityChange || (() => {})} />
    ),
    path: "courses",
  },
  {
    title: "¡Último paso!",
    component: ({ onPaymentClick }: StepComponentProps) => (
      <ReviewStep
        onPaymentClick={() => {
          window.open("https://mpago.la/1wVaz4P", "_blank");
          onPaymentClick?.();
        }}
      />
    ),
    path: "review",
  },
];

const IMPROVE_STEPS = [
  {
    title: "Subí tu CV actual 📄",
    component: UploadStep,
    path: "upload",
  },
  {
    title: "Elegí un diseño ✨",
    component: ({
      onTemplateSelect,
      selectedTemplate,
      onValidityChange,
    }: StepComponentProps) => (
      <TemplateStep
        onTemplateSelect={(template) => onTemplateSelect?.(template)}
        selectedTemplate={selectedTemplate}
        showKeepCurrent={true}
        onValidityChange={(isValid) => onValidityChange?.(isValid)}
      />
    ),
    path: "template",
  },
  CREATE_STEPS[CREATE_STEPS.length - 1],
];

export const GenericWorkflowCV: React.FC<GenericWorkflowCVProps> = ({
  onExit,
  mode,
  onTemplateSelect: parentOnTemplateSelect,
  selectedTemplate: parentSelectedTemplate,
  onStepValidityChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const steps = mode === "create" ? CREATE_STEPS : IMPROVE_STEPS;

  const currentStep = Math.max(
    0,
    steps.findIndex((step) =>
      location.pathname.includes(`/${mode}/${step.path}`)
    )
  );

  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateOption | undefined
  >(parentSelectedTemplate);
  const [showExitAlert, setShowExitAlert] = useState(false);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      navigate(`/cv/${mode}/${steps[currentStep + 1].path}`);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      navigate(`/cv/${mode}/${steps[currentStep - 1].path}`);
    }
  };

  const handlePaymentClick = () => {
    console.log("Payment button clicked");
  };

  const handleTemplateSelect = (template: TemplateOption) => {
    console.log("Template selected:", template);
    setSelectedTemplate(template);
    parentOnTemplateSelect?.(template);
  };

  const handleFormValidityChange = (isValid: boolean) => {
    setIsFormValid(isValid);
    onStepValidityChange?.(isValid);
  };

  const handleExit = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setShowExitAlert(true);
  };

  const handleExitConfirm = () => {
    setShowExitAlert(false);
    window.onbeforeunload = null;
    onExit();
  };

  const handleExitCancel = () => {
    setShowExitAlert(false);
  };

  const StepComponent = steps[currentStep].component;

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/cv/${mode}/${steps[0].path}`} replace />}
        />
        {steps.map((step, index) => (
          <Route
            key={step.path}
            path={step.path}
            element={
              <Wizard
                currentStep={index}
                totalSteps={steps.length}
                stepTitle={step.title}
                onNext={handleNext}
                onBack={handleBack}
                onExit={handleExit}
                canGoBack={index > 0}
                canGoNext={isFormValid}
              >
                <StepComponent
                  onPaymentClick={handlePaymentClick}
                  onTemplateSelect={handleTemplateSelect}
                  selectedTemplate={selectedTemplate}
                  onValidityChange={handleFormValidityChange}
                />
              </Wizard>
            }
          />
        ))}
      </Routes>
      <AlertModal
        isOpen={showExitAlert}
        onConfirm={handleExitConfirm}
        onCancel={handleExitCancel}
      />
    </>
  );
};
