import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (email: string) => void;
}

interface UserData {
  email: string;
  names: string;
  surnames: string;
}

export function AuthModal({ isOpen, onClose, onSuccess }: AuthModalProps) {
  const [userData, setUserData] = useState<UserData>({
    email: "",
    names: "",
    surnames: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { startEmailAuth } = useAuth();

  useEffect(() => {
    const lastAttempt = localStorage.getItem("lastAuthAttempt");
    if (lastAttempt) {
      const elapsed = Date.now() - parseInt(lastAttempt);
      const remaining = Math.max(0, 120000 - elapsed); // 120000ms = 2 minutes
      setCooldownRemaining(remaining);

      if (remaining > 0) {
        const timer = setInterval(() => {
          setCooldownRemaining((prev) => {
            const newValue = Math.max(0, prev - 1000);
            if (newValue === 0) {
              clearInterval(timer);
            }
            return newValue;
          });
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (cooldownRemaining > 0) return;

    setIsSubmitting(true);
    try {
      await startEmailAuth(userData.email, {
        names: userData.names,
        surnames: userData.surnames,
      });

      localStorage.setItem("lastAuthAttempt", Date.now().toString());
      localStorage.setItem(
        "userFullName",
        JSON.stringify({
          names: userData.names,
          surnames: userData.surnames,
        })
      );
      setCooldownRemaining(120000);
      setShowSuccessMessage(true);
      onSuccess(userData.email);
    } catch (error) {
      console.error("Authentication error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatTime = (ms: number) => {
    const seconds = Math.ceil(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="close-button" onClick={onClose} aria-label="Cerrar">
          &times;
        </button>
        {showSuccessMessage ? (
          <div className="success-message">
            <h2>¡Revisa tu email!</h2>
            <p>
              Hola {userData.names}, hemos enviado un enlace mágico a{" "}
              {userData.email}. Haz clic en el enlace para iniciar sesión.
            </p>
          </div>
        ) : (
          <>
            <h2>Ingresa tus datos para continuar</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="names"
                value={userData.names}
                onChange={handleInputChange}
                placeholder="Nombres"
                required
              />
              <input
                type="text"
                name="surnames"
                value={userData.surnames}
                onChange={handleInputChange}
                placeholder="Apellidos"
                required
              />
              <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                placeholder="Tu email"
                required
              />
              <p className="email-disclaimer">
                Te enviaremos un link mágico ✨ y usaremos este correo para
                estar en contacto. ¡Asegurate que sea válido! 🚀
              </p>
              <button
                type="submit"
                disabled={isSubmitting}
                className={cooldownRemaining > 0 ? "in-cooldown" : ""}
              >
                {isSubmitting
                  ? "Enviando..."
                  : cooldownRemaining > 0
                  ? `Espera ${formatTime(cooldownRemaining)}`
                  : "Enviar link mágico"}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
