export const formatDNI = (value: string): string => {
  // Remove non-digits
  const digits = value.replace(/\D/g, "");
  // Format as XX.XXX.XXX
  return digits.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2.$3");
};

export const formatPhone = (value: string): string => {
  // Remove non-digits
  const digits = value.replace(/\D/g, "");
  // Format as (XX) XXXX-XXXX
  return digits.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
};

export const formatDate = (value: string): string => {
  // Remove non-digits
  const digits = value.replace(/\D/g, "");
  // Format as DD/MM/YYYY
  return digits.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
};
