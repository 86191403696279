import React, { useState, useEffect } from "react";
import mpLogo from "../assets/mp-logo.png";
import juliProfile from "../assets/juli-profile.png";
import francoProfile from "../assets/franco-profile.png";
import { AuthModal } from "./AuthModal";
import { useAuth } from "../context/AuthContext";
import sharedStyles from "../styles/shared.module.css";
import { GenericWorkflowCV } from "./GenericWorkflowCV/GenericWorkflowCV";

function Home() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [, setPendingAction] = useState<"create" | "improve" | null>(null);
  const [showEmailSentMessage, setShowEmailSentMessage] = useState(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const { isAuthenticated, logout } = useAuth();
  const [showWizard, setShowWizard] = useState(false);
  const [cvMode, setCvMode] = useState<"create" | "improve">("create");
  const [activeSection, setActiveSection] = useState("main-section");

  useEffect(() => {
    const pendingAuth = localStorage.getItem("pendingAuth");
    if (pendingAuth && !isAuthenticated) {
      setShowEmailSentMessage(true);
      setUserEmail(pendingAuth);
    } else {
      setShowEmailSentMessage(false);
      localStorage.removeItem("pendingAuth");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const handleScroll = () => {
      const mainSection = document.getElementById("main-section");
      const teamSection = document.getElementById("team-section");

      if (mainSection && teamSection) {
        const teamRect = teamSection.getBoundingClientRect();

        if (teamRect.top <= 100) {
          setActiveSection("team-section");
        } else {
          setActiveSection("main-section");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAction = (action: "create" | "improve") => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      setPendingAction(action);
      setCvMode(action);
    } else {
      handleAuthenticatedAction(action);
    }
  };

  const handleAuthSuccess = (email: string) => {
    setShowAuthModal(false);
    setShowEmailSentMessage(true);
    setUserEmail(email);
    localStorage.setItem("pendingAuth", email);
  };

  const handleAuthenticatedAction = (action: "create" | "improve") => {
    setCvMode(action);
    setShowWizard(true);
  };

  const handleDismissMessage = () => {
    setShowEmailSentMessage(false);
    localStorage.removeItem("pendingAuth");
    logout();
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveSection(sectionId);
    }
  };

  if (showWizard) {
    return (
      <GenericWorkflowCV onExit={() => setShowWizard(false)} mode={cvMode} />
    );
  }

  return (
    <div className="home-container">
      <nav className="section-nav">
        <button
          className={`nav-link ${
            activeSection === "main-section" ? "active" : ""
          }`}
          onClick={() => scrollToSection("main-section")}
        >
          Principal
        </button>
        <button
          className={`nav-link ${
            activeSection === "team-section" ? "active" : ""
          }`}
          onClick={() => scrollToSection("team-section")}
        >
          ¿Quiénes somos?
        </button>
      </nav>

      {showEmailSentMessage && (
        <div className="email-sent-message">
          <p>¡Revisa tu email! 📧</p>
          <p className="email-sent-details">
            Hemos enviado un link mágico a <strong>{userEmail}</strong>
          </p>
          <button className="dismiss-button" onClick={handleDismissMessage}>
            ×
          </button>
        </div>
      )}

      <section id="main-section" className="App-header section">
        <h1>Curriculum</h1>
        <p className="tagline">
          Creá un CV profesional y destacá en tu carrera 🚀
        </p>

        <div className="pricing-offer">
          <div className="ribbon">
            <span>50% OFF</span>
          </div>
          <div className="offer-badge">OFERTA</div>
          <div className="offer-content">
            <div className="original-price">
              <span className="strike-through">$10.000 ARS</span>
            </div>
            <div className="offer-price">
              <div className="price-now">
                <span className="price-amount">$5.000 ARS</span>
              </div>
            </div>
            <div className="payment-info">
              <span>Pago único con</span>
              <img src={mpLogo} alt="Mercado Pago" className="mp-logo" />
            </div>
            <div className="secure-badge">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
              </svg>
              <span>Pago seguro verificado</span>
            </div>
          </div>
        </div>

        <div className="cta-container">
          <button
            className={`${sharedStyles.button} ${sharedStyles.primary} ${
              showEmailSentMessage ? "waiting" : ""
            }`}
            onClick={() => handleAction("create")}
          >
            Creá tu primer CV 📝
          </button>
          <button
            className={`${sharedStyles.button} ${sharedStyles.secondary} ${
              showEmailSentMessage ? "waiting" : ""
            }`}
            onClick={() => handleAction("improve")}
          >
            Mejorá tu CV actual ⭐
          </button>
        </div>
      </section>

      <section id="team-section" className="team-section section">
        <h2>¿Quiénes somos?</h2>
        <div className="team-members">
          <div className="team-member">
            <img src={juliProfile} alt="Juli" className="profile-photo" />
            <h3>Juli</h3>
            <p>Experta en Recursos Humanos</p>
          </div>
          <div className="team-member">
            <img src={francoProfile} alt="Franco" className="profile-photo" />
            <h3>Franco</h3>
            <p>Desarrollador de Software</p>
          </div>
        </div>
      </section>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onSuccess={handleAuthSuccess}
      />
    </div>
  );
}

export default Home;
