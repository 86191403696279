import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./CoursesAndMoreStep.module.css";

interface Course {
  id: string;
  name: string;
  institution: string;
  startYear: string;
  endYear: string;
}

interface CoursesAndMoreStepProps {
  onValidityChange: (isValid: boolean) => void;
}

const allowOnlyNumbers = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const CoursesAndMoreStep: React.FC<CoursesAndMoreStepProps> = ({
  onValidityChange,
}) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [aboutMe, setAboutMe] = useState("");
  const [hobbies, setHobbies] = useState("");

  const {
    control,
    formState: { errors },
    unregister,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (courses.length === 0) {
      onValidityChange(true);
      return;
    }

    const hasErrors = Object.keys(errors).length > 0;
    const isValid = !hasErrors;
    onValidityChange(isValid);
  }, [courses, errors, onValidityChange]);

  const addCourse = () => {
    setCourses((prev) => [
      ...prev,
      {
        id: String(Date.now()),
        name: "",
        institution: "",
        startYear: "",
        endYear: "",
      },
    ]);
    setTimeout(() => trigger(), 0);
  };

  const removeCourse = (id: string) => {
    unregister([
      `courseName-${id}`,
      `institution-${id}`,
      `startYear-${id}`,
      `endYear-${id}`,
    ]);
    setCourses((prev) => prev.filter((course) => course.id !== id));
    setTimeout(() => trigger(), 0);
  };

  return (
    <div className={styles.form}>
      <div className={styles.section}>
        <h3 className={styles.sectionTitle}>
          <span className={styles.icon}>📚</span> Cursos y Certificaciones
        </h3>

        {courses.map((course, index) => (
          <div key={course.id} className={styles.courseCard}>
            <div className={styles.cardHeader}>
              <h4>Curso {index + 1}</h4>
              <button
                type="button"
                onClick={() => removeCourse(course.id)}
                className={styles.removeButton}
                aria-label="Eliminar curso"
              >
                ✕
              </button>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor={`courseName-${course.id}`}>
                  <span className={styles.icon}>📝</span> Nombre del curso *
                </label>
                <Controller
                  name={`courseName-${course.id}`}
                  control={control}
                  rules={{
                    required: "El nombre del curso es requerido",
                    minLength: {
                      value: 2,
                      message: "El nombre debe tener al menos 2 caracteres",
                    },
                  }}
                  defaultValue={course.name}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        id={`courseName-${course.id}`}
                        placeholder="¿Qué curso realizaste?"
                        className={error ? styles.hasError : ""}
                      />
                      {error && (
                        <span className={styles.error}>{error.message}</span>
                      )}
                    </>
                  )}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor={`institution-${course.id}`}>
                  <span className={styles.icon}>🏛️</span> Institución *
                </label>
                <Controller
                  name={`institution-${course.id}`}
                  control={control}
                  rules={{
                    required: "La institución es requerida",
                    minLength: {
                      value: 2,
                      message:
                        "La institución debe tener al menos 2 caracteres",
                    },
                  }}
                  defaultValue={course.institution}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        id={`institution-${course.id}`}
                        placeholder="¿Dónde lo realizaste?"
                        className={error ? styles.hasError : ""}
                      />
                      {error && (
                        <span className={styles.error}>{error.message}</span>
                      )}
                    </>
                  )}
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor={`startYear-${course.id}`}>
                  <span className={styles.icon}>📅</span> Año de inicio *
                </label>
                <Controller
                  name={`startYear-${course.id}`}
                  control={control}
                  rules={{
                    required: "El año de inicio es requerido",
                    pattern: {
                      value: /^(19|20)\d{2}$/,
                      message: "Ingresá un año válido (1900-2099)",
                    },
                  }}
                  defaultValue={course.startYear}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        id={`startYear-${course.id}`}
                        placeholder="¿Cuándo empezaste?"
                        maxLength={4}
                        className={error ? styles.hasError : ""}
                        onChange={(e) => {
                          const numbersOnly = allowOnlyNumbers(e.target.value);
                          field.onChange(numbersOnly);
                        }}
                      />
                      {error && (
                        <span className={styles.error}>{error.message}</span>
                      )}
                    </>
                  )}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor={`endYear-${course.id}`}>
                  <span className={styles.icon}>📅</span> Año de finalización
                </label>
                <Controller
                  name={`endYear-${course.id}`}
                  control={control}
                  rules={{
                    pattern: {
                      value: /^(19|20)\d{2}$/,
                      message: "Ingresá un año válido (1900-2099)",
                    },
                  }}
                  defaultValue={course.endYear}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        id={`endYear-${course.id}`}
                        placeholder="Podés dejarlo en blanco"
                        maxLength={4}
                        className={error ? styles.hasError : ""}
                        onChange={(e) => {
                          const numbersOnly = allowOnlyNumbers(e.target.value);
                          field.onChange(numbersOnly);
                        }}
                      />
                      {error && (
                        <span className={styles.error}>{error.message}</span>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        ))}

        <button type="button" onClick={addCourse} className={styles.addButton}>
          + Agregar curso
        </button>
      </div>

      <div className={styles.section}>
        <h3 className={styles.sectionTitle}>
          <span className={styles.icon}>📝</span> Sobre mí
        </h3>
        <div className={styles.inputWithTips}>
          <textarea
            value={aboutMe}
            onChange={(e) => setAboutMe(e.target.value)}
            placeholder="Escribí una breve descripción sobre vos..."
            className={styles.textArea}
            rows={4}
          />
          <div className={styles.tipBox}>
            <strong>Tip:</strong>{" "}
            <span className={styles.tipText}>
              Describí tu perfil profesional, tus objetivos y qué te motiva.
            </span>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <h3 className={styles.sectionTitle}>
          <span className={styles.icon}>🎯</span> Hobbies e Intereses
        </h3>
        <div className={styles.inputWithTips}>
          <textarea
            value={hobbies}
            onChange={(e) => setHobbies(e.target.value)}
            placeholder="¿Qué te gusta hacer en tu tiempo libre?"
            className={styles.textArea}
            rows={3}
          />
          <div className={styles.tipBox}>
            <strong>Tip:</strong>{" "}
            <span className={styles.tipText}>
              Agregá actividades que muestren tus habilidades o valores
              personales.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
