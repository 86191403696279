import React, { useState, useRef } from "react";
import styles from "./UploadStep.module.css";
import { Modal } from "../../shared/Modal/Modal";

interface FileUpload {
  file: File;
  preview?: string;
}

interface UploadStepProps {
  onValidityChange: (isValid: boolean) => void;
}

export const UploadStep: React.FC<UploadStepProps> = ({ onValidityChange }) => {
  const [cvFile, setCvFile] = useState<FileUpload | null>(null);
  const [photoFile, setPhotoFile] = useState<FileUpload | null>(null);
  const [isPhotoTipsModalOpen, setIsPhotoTipsModalOpen] = useState(false);
  const cvInputRef = useRef<HTMLInputElement>(null);
  const photoInputRef = useRef<HTMLInputElement>(null);

  // Update parent component when CV file changes
  React.useEffect(() => {
    onValidityChange(cvFile !== null);
  }, [cvFile, onValidityChange]);

  const handleCvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setCvFile({ file });
    }
  };

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoFile({ file, preview: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCvDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (
      file &&
      (file.type === "application/pdf" || file.type.includes("word"))
    ) {
      setCvFile({ file });
    }
  };

  const handlePhotoDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoFile({ file, preview: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <div className={styles.uploadSection}>
        <h3>
          Subí tu CV actual 📄
          <span className={styles.requiredIndicator}>*</span>
        </h3>
        <div
          className={`${styles.dropZone} ${cvFile ? styles.hasFile : ""} ${
            !cvFile ? styles.required : ""
          }`}
          onClick={() => cvInputRef.current?.click()}
          onDrop={handleCvDrop}
          onDragOver={handleDragOver}
        >
          <input
            ref={cvInputRef}
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleCvUpload}
            style={{ display: "none" }}
          />
          {cvFile ? (
            <div className={styles.fileInfo}>
              <div className={styles.fileDetails}>
                <span className={styles.fileName}>{cvFile.file.name}</span>
                <span className={styles.fileSize}>
                  {(cvFile.file.size / 1024 / 1024).toFixed(2)} MB
                </span>
              </div>
              <div className={styles.fileActions}>
                <button
                  className={styles.changeButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    cvInputRef.current?.click();
                  }}
                >
                  Cambiar archivo
                </button>
                <button
                  className={styles.removeButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCvFile(null);
                    if (cvInputRef.current) {
                      cvInputRef.current.value = "";
                    }
                  }}
                >
                  ✕
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.uploadIcon}>📎</div>
              <p>Hacé click o arrastrá tu archivo aquí</p>
              <span className={styles.fileTypes}>PDF, DOC o DOCX</span>
            </>
          )}
        </div>
        {!cvFile && (
          <span className={styles.requiredMessage}>
            Es necesario subir tu CV para continuar
          </span>
        )}
      </div>

      <div className={styles.uploadSection}>
        <div className={styles.sectionHeader}>
          <h3>Foto de perfil (opcional)</h3>
          <button
            type="button"
            className={styles.tipsButton}
            onClick={() => setIsPhotoTipsModalOpen(true)}
          >
            Ver consejos para una buena foto 📸
          </button>
        </div>

        <div className={styles.photoContainer}>
          <div
            className={`${styles.photoDropZone} ${
              photoFile ? styles.hasFile : ""
            }`}
            onClick={() => photoInputRef.current?.click()}
            onDrop={handlePhotoDrop}
            onDragOver={handleDragOver}
          >
            <input
              ref={photoInputRef}
              type="file"
              accept="image/*"
              onChange={handlePhotoUpload}
              style={{ display: "none" }}
            />
            {photoFile ? (
              <div className={styles.photoPreview}>
                <img src={photoFile.preview} alt="Preview" />
                <div className={styles.photoActions}>
                  <button
                    className={styles.changePhotoButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      photoInputRef.current?.click();
                    }}
                  >
                    Cambiar
                  </button>
                  <button
                    className={styles.removePhotoButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPhotoFile(null);
                      if (photoInputRef.current) {
                        photoInputRef.current.value = "";
                      }
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.uploadIcon}>📸</div>
                <p>Subí tu mejor foto</p>
                <span className={styles.fileTypes}>JPG, PNG</span>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isPhotoTipsModalOpen}
        onClose={() => setIsPhotoTipsModalOpen(false)}
        title="Consejos para una buena foto 📸"
      >
        <div className={styles.photoTips}>
          <ul>
            <li>✅ La foto debe ser lo más actual posible.</li>
            <li>
              ❌ No uses fotos que tengas en bares, parques y situaciones
              cotidianas 🍻
            </li>
            <li>
              ✅ El fondo debe ser claro (puede ser una pared) o neutro, que no
              distraiga tu imagen.
            </li>
            <li>
              ❌ Intentá que no sea selfie 🤳. Podés apoyar el celular o cámara
              en algún soporte.
            </li>
            <li>✅ No hace falta que estés serio/a, podes sonreír 😁</li>
            <li>
              ✅ No debe verse borrosa, asegurate que la cámara esté enfocada.
            </li>
            <li>
              ✅ Vestimenta y posición adecuada: no inclines la cabeza para los
              lados, no hagas muecas.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};
