import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./WorkExperienceStep.module.css";

interface WorkExperience {
  id: string;
  company: string;
  role: string;
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  achievements: string;
}

interface WorkExperienceStepProps {
  onValidityChange: (isValid: boolean) => void;
}

const allowOnlyNumbers = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const WorkExperienceStep: React.FC<WorkExperienceStepProps> = ({
  onValidityChange,
}) => {
  const [experiences, setExperiences] = useState<WorkExperience[]>([
    {
      id: "1",
      company: "",
      role: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      achievements: "",
    },
  ]);

  const {
    control,
    formState: { isValid, errors, isDirty, touchedFields },
    unregister,
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    if (isDirty || Object.keys(touchedFields).length > 0) {
      onValidityChange(isValid && Object.keys(errors).length === 0);
    }
  }, [isValid, errors, onValidityChange, isDirty, touchedFields]);

  const handleInputChange = (
    id: string,
    field: keyof WorkExperience,
    value: string
  ) => {
    setExperiences((prev) =>
      prev.map((exp) => (exp.id === id ? { ...exp, [field]: value } : exp))
    );
  };

  const addExperience = () => {
    setExperiences((prev) => [
      ...prev,
      {
        id: String(Date.now()),
        company: "",
        role: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        achievements: "",
      },
    ]);
  };

  const removeExperience = (id: string) => {
    if (experiences.length > 1) {
      unregister([
        `company-${id}`,
        `role-${id}`,
        `startMonth-${id}`,
        `startYear-${id}`,
        `endMonth-${id}`,
        `achievements-${id}`,
      ]);

      setExperiences((prev) => prev.filter((exp) => exp.id !== id));
    }
  };

  return (
    <form className={styles.form}>
      {experiences.map((experience, index) => (
        <div key={experience.id} className={styles.experienceCard}>
          <div className={styles.cardHeader}>
            <h3>Experiencia {index + 1}</h3>
            {experiences.length > 1 && (
              <button
                type="button"
                onClick={() => removeExperience(experience.id)}
                className={styles.removeButton}
                aria-label="Eliminar experiencia"
              >
                ✕
              </button>
            )}
          </div>

          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor={`company-${experience.id}`}>
                <span className={styles.icon}>🏢</span> Empresa
              </label>
              <Controller
                name={`company-${experience.id}`}
                control={control}
                rules={{
                  required: "La empresa es requerida",
                  minLength: {
                    value: 2,
                    message: "La empresa debe tener al menos 2 caracteres",
                  },
                }}
                defaultValue={experience.company}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      {...field}
                      type="text"
                      id={`company-${experience.id}`}
                      placeholder="¿Dónde trabajaste?"
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(
                          experience.id,
                          "company",
                          e.target.value
                        );
                      }}
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                )}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor={`role-${experience.id}`}>
                <span className={styles.icon}>💼</span> Puesto
              </label>
              <Controller
                name={`role-${experience.id}`}
                control={control}
                rules={{
                  required: "El puesto es requerido",
                  minLength: {
                    value: 2,
                    message: "El puesto debe tener al menos 2 caracteres",
                  },
                }}
                defaultValue={experience.role}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      {...field}
                      type="text"
                      id={`role-${experience.id}`}
                      placeholder="¿Qué puesto ocupaste?"
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(
                          experience.id,
                          "role",
                          e.target.value
                        );
                      }}
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor={`startDate-${experience.id}`}>
                <span className={styles.icon}>📅</span> Fecha de inicio
              </label>
              <div className={styles.dateInputGroup}>
                <Controller
                  name={`startMonth-${experience.id}`}
                  control={control}
                  rules={{ required: "El mes es requerido" }}
                  defaultValue={experience.startMonth}
                  render={({ field, fieldState: { error } }) => (
                    <select
                      {...field}
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(
                          experience.id,
                          "startMonth",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Mes</option>
                      <option value="01">Enero</option>
                      <option value="02">Febrero</option>
                      <option value="03">Marzo</option>
                      <option value="04">Abril</option>
                      <option value="05">Mayo</option>
                      <option value="06">Junio</option>
                      <option value="07">Julio</option>
                      <option value="08">Agosto</option>
                      <option value="09">Septiembre</option>
                      <option value="10">Octubre</option>
                      <option value="11">Noviembre</option>
                      <option value="12">Diciembre</option>
                    </select>
                  )}
                />
                <Controller
                  name={`startYear-${experience.id}`}
                  control={control}
                  rules={{
                    required: "El año es requerido",
                    pattern: {
                      value: /^(19|20)\d{2}$/,
                      message: "Ingresá un año válido (1900-2099)",
                    },
                  }}
                  defaultValue={experience.startYear}
                  render={({ field, fieldState: { error } }) => (
                    <input
                      {...field}
                      type="text"
                      placeholder="Año"
                      maxLength={4}
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        const numbersOnly = allowOnlyNumbers(e.target.value);
                        field.onChange(numbersOnly);
                        handleInputChange(
                          experience.id,
                          "startYear",
                          numbersOnly
                        );
                      }}
                    />
                  )}
                />
              </div>
              {(errors[`startMonth-${experience.id}`] ||
                errors[`startYear-${experience.id}`]) && (
                <span className={styles.error}>Fecha de inicio requerida</span>
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor={`endDate-${experience.id}`}>
                <span className={styles.icon}>📅</span> Fecha de finalización
              </label>
              <div className={styles.dateInputGroup}>
                <select
                  value={experience.endMonth}
                  onChange={(e) =>
                    handleInputChange(experience.id, "endMonth", e.target.value)
                  }
                >
                  <option value="">Mes</option>
                  <option value="01">Enero</option>
                  <option value="02">Febrero</option>
                  <option value="03">Marzo</option>
                  <option value="04">Abril</option>
                  <option value="05">Mayo</option>
                  <option value="06">Junio</option>
                  <option value="07">Julio</option>
                  <option value="08">Agosto</option>
                  <option value="09">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
                <input
                  type="text"
                  value={experience.endYear}
                  onChange={(e) => {
                    const numbersOnly = allowOnlyNumbers(e.target.value);
                    handleInputChange(experience.id, "endYear", numbersOnly);
                  }}
                  placeholder="Año"
                  maxLength={4}
                />
              </div>
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor={`achievements-${experience.id}`}>
              <span className={styles.icon}>🎯</span> Logros y responsabilidades
            </label>
            <Controller
              name={`achievements-${experience.id}`}
              control={control}
              rules={{
                required: "Los logros y responsabilidades son requeridos",
                minLength: {
                  value: 10,
                  message: "La descripción debe tener al menos 10 caracteres",
                },
              }}
              defaultValue={experience.achievements}
              render={({ field, fieldState: { error } }) => (
                <>
                  <textarea
                    {...field}
                    id={`achievements-${experience.id}`}
                    placeholder="Describí tus principales logros y responsabilidades..."
                    rows={4}
                    className={error ? styles.hasError : ""}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(
                        experience.id,
                        "achievements",
                        e.target.value
                      );
                    }}
                  />
                  {error && (
                    <span className={styles.error}>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={addExperience}
        className={styles.addButton}
      >
        + Agregar otra experiencia
      </button>
    </form>
  );
};
