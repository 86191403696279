import React, { useState, useEffect } from "react";
import styles from "./TemplateStep.module.css";
import classicGif from "../../../assets/templates/classic.gif";
import creativeGif from "../../../assets/templates/creative.gif";
import modernGif from "../../../assets/templates/modern.gif";

export type TemplateOption = "classic" | "creative" | "modern" | "keep-current";

interface TemplateStepProps {
  onTemplateSelect: (template: TemplateOption) => void;
  selectedTemplate?: TemplateOption;
  showKeepCurrent?: boolean;
  onValidityChange: (isValid: boolean) => void;
}

export const TemplateStep: React.FC<TemplateStepProps> = ({
  onTemplateSelect,
  selectedTemplate,
  showKeepCurrent = false,
  onValidityChange,
}) => {
  const [hoveredTemplate, setHoveredTemplate] = useState<TemplateOption | null>(
    null
  );

  useEffect(() => {
    const isValid = selectedTemplate !== undefined;
    onValidityChange(isValid);
  }, [selectedTemplate, onValidityChange]);

  const templates = [
    {
      id: "classic" as TemplateOption,
      name: "Clásico",
      gif: classicGif,
      description:
        "Diseño tradicional y elegante, ideal para roles corporativos",
    },
    {
      id: "creative" as TemplateOption,
      name: "Creativo",
      gif: creativeGif,
      description: "Destaca tu personalidad con un diseño único y moderno",
    },
    {
      id: "modern" as TemplateOption,
      name: "Moderno",
      gif: modernGif,
      description: "Diseño contemporáneo que combina profesionalismo y estilo",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.templatesGrid}>
        {templates.map((template) => (
          <div
            key={template.id}
            className={`${styles.templateCard} ${
              selectedTemplate === template.id ? styles.selected : ""
            } ${!selectedTemplate ? styles.unselected : ""}`}
            onClick={() => onTemplateSelect(template.id)}
            onMouseEnter={() => setHoveredTemplate(template.id)}
            onMouseLeave={() => setHoveredTemplate(null)}
          >
            <div className={styles.templatePreview}>
              <img src={template.gif} alt={template.name} />
              {hoveredTemplate === template.id && (
                <div className={styles.previewOverlay}>
                  <button className={styles.selectButton}>Seleccionar</button>
                </div>
              )}
            </div>
            <div className={styles.templateInfo}>
              <h3>{template.name}</h3>
              <p>{template.description}</p>
            </div>
          </div>
        ))}
      </div>

      {showKeepCurrent && (
        <div className={styles.keepCurrentOption}>
          <label className={styles.keepCurrentLabel}>
            <input
              type="radio"
              name="template"
              value="keep-current"
              checked={selectedTemplate === "keep-current"}
              onChange={() => onTemplateSelect("keep-current")}
            />
            <span>Mantener el diseño actual de mi CV</span>
          </label>
        </div>
      )}
    </div>
  );
};
