import React, { useState, KeyboardEvent, useEffect } from "react";
import styles from "./SkillsStep.module.css";

interface Skill {
  id: string;
  name: string;
  level: number;
  type: "soft" | "hard";
}

interface SkillsStepProps {
  onValidityChange: (isValid: boolean) => void;
}

export const SkillsStep: React.FC<SkillsStepProps> = ({ onValidityChange }) => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [hardSkillInput, setHardSkillInput] = useState("");
  const [softSkillInput, setSoftSkillInput] = useState("");
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [duplicateError, setDuplicateError] = useState<{
    type: "soft" | "hard";
    name: string;
  } | null>(null);

  const hasHardSkill = skills.some((skill) => skill.type === "hard");
  const hasSoftSkill = skills.some((skill) => skill.type === "soft");
  const isValid = hasHardSkill && hasSoftSkill;

  useEffect(() => {
    onValidityChange(isValid);
    if (skills.length > 0) {
      setShowValidationMessage(true);
    }
  }, [skills, isValid, onValidityChange]);

  const handleAddSkill = (skillName: string, type: "soft" | "hard") => {
    const trimmedSkill = skillName.trim();
    if (trimmedSkill) {
      const isDuplicate = skills.some(
        (skill) => skill.name.toLowerCase() === trimmedSkill.toLowerCase()
      );

      if (isDuplicate) {
        setDuplicateError({ type, name: trimmedSkill });
        setTimeout(() => setDuplicateError(null), 3000); // Clear error after 3 seconds
        return;
      }

      setSkills((prev) => [
        ...prev,
        {
          id: String(Date.now()),
          name: trimmedSkill,
          level: 3,
          type,
        },
      ]);
      if (type === "hard") {
        setHardSkillInput("");
      } else {
        setSoftSkillInput("");
      }
      setDuplicateError(null);
    }
  };

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement>,
    type: "soft" | "hard"
  ) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddSkill(type === "hard" ? hardSkillInput : softSkillInput, type);
    }
  };

  const handleInputChange = (id: string, field: keyof Skill, value: any) => {
    setSkills((prev) =>
      prev.map((skill) =>
        skill.id === id ? { ...skill, [field]: value } : skill
      )
    );
  };

  const removeSkill = (id: string) => {
    setSkills((prev) => prev.filter((skill) => skill.id !== id));
  };

  const getSkillsByType = (type: "soft" | "hard") => {
    return skills.filter((skill) => skill.type === type);
  };

  return (
    <div className={styles.form}>
      {/* Hard Skills Section */}
      <div className={styles.skillsSection}>
        <h3 className={styles.skillsTitle}>
          <span className={styles.icon}>💻</span> Habilidades Técnicas
          {showValidationMessage && !hasHardSkill && (
            <span className={styles.validationMessage}>
              ⚠️ Agregá al menos una habilidad técnica
            </span>
          )}
        </h3>
        <div className={styles.inputWithTips}>
          <div className={styles.inputContainer}>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={hardSkillInput}
                onChange={(e) => setHardSkillInput(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "hard")}
                placeholder="Escribí y presioná Enter"
                className={`${styles.skillInput} ${
                  (showValidationMessage && !hasHardSkill) ||
                  duplicateError?.type === "hard"
                    ? styles.inputError
                    : ""
                }`}
              />
              {duplicateError?.type === "hard" && (
                <span className={styles.duplicateError}>
                  ⚠️ "{duplicateError.name}" ya fue agregada
                </span>
              )}
            </div>
            <div className={styles.tipBox}>
              <strong>Ejemplos:</strong>{" "}
              <span className={styles.tipText}>
                Microsoft Excel, Manejo de autoelevador, Inglés B2, etc.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.skillsContainer}>
          {getSkillsByType("hard").map((skill) => (
            <div key={skill.id} className={styles.skillTag}>
              <span className={styles.skillName}>{skill.name}</span>
              <div className={styles.starRating}>
                {[1, 2, 3, 4, 5].map((value) => (
                  <label key={value} className={styles.starLabel}>
                    <input
                      type="radio"
                      name={`rating-${skill.id}`}
                      value={value}
                      checked={skill.level === value}
                      onChange={(e) =>
                        handleInputChange(
                          skill.id,
                          "level",
                          Number(e.target.value)
                        )
                      }
                      className={styles.starInput}
                    />
                    <span
                      className={`${styles.star} ${
                        value <= skill.level ? styles.starActive : ""
                      }`}
                    >
                      ★
                    </span>
                  </label>
                ))}
              </div>
              <button
                type="button"
                onClick={() => removeSkill(skill.id)}
                className={styles.removeTag}
                aria-label="Eliminar habilidad"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Soft Skills Section */}
      <div className={styles.skillsSection}>
        <h3 className={styles.skillsTitle}>
          <span className={styles.icon}>🤝</span> Habilidades Blandas
          {showValidationMessage && !hasSoftSkill && (
            <span className={styles.validationMessage}>
              ⚠️ Agregá al menos una habilidad blanda
            </span>
          )}
        </h3>
        <div className={styles.inputWithTips}>
          <div className={styles.inputContainer}>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={softSkillInput}
                onChange={(e) => setSoftSkillInput(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "soft")}
                placeholder="Escribí y presioná Enter"
                className={`${styles.skillInput} ${
                  (showValidationMessage && !hasSoftSkill) ||
                  duplicateError?.type === "soft"
                    ? styles.inputError
                    : ""
                }`}
              />
              {duplicateError?.type === "soft" && (
                <span className={styles.duplicateError}>
                  ⚠️ "{duplicateError.name}" ya fue agregada
                </span>
              )}
            </div>
            <div className={styles.tipBox}>
              <strong>Ejemplos:</strong>{" "}
              <span className={styles.tipText}>
                Comunicación efectiva, Trabajo en equipo, Resolución de
                problemas, Gestión del tiempo, Liderazgo, etc.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.skillsContainer}>
          {getSkillsByType("soft").map((skill) => (
            <div key={skill.id} className={styles.skillTag}>
              <span className={styles.skillName}>{skill.name}</span>
              <div className={styles.starRating}>
                {[1, 2, 3, 4, 5].map((value) => (
                  <label key={value} className={styles.starLabel}>
                    <input
                      type="radio"
                      name={`rating-${skill.id}`}
                      value={value}
                      checked={skill.level === value}
                      onChange={(e) =>
                        handleInputChange(
                          skill.id,
                          "level",
                          Number(e.target.value)
                        )
                      }
                      className={styles.starInput}
                    />
                    <span
                      className={`${styles.star} ${
                        value <= skill.level ? styles.starActive : ""
                      }`}
                    >
                      ★
                    </span>
                  </label>
                ))}
              </div>
              <button
                type="button"
                onClick={() => removeSkill(skill.id)}
                className={styles.removeTag}
                aria-label="Eliminar habilidad"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
