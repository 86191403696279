import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "../../../context/AuthContext";
import styles from "./PersonalInfoStep.module.css";
import { formatDate, formatDNI, formatPhone } from "../../../utils/formatters";
import { Modal } from "../../shared/Modal/Modal";
import useFormPersist from "react-hook-form-persist";

interface PersonalInfo {
  names: string;
  surnames: string;
  dateOfBirth: string;
  dni: string;
  gender: "" | "male" | "female" | "other" | "prefer_not_to_say";
  phone: string;
  email: string;
  linkedin?: string;
  city: string;
}

interface FileUpload {
  file: File;
  preview?: string;
}

interface PersonalInfoStepProps {
  onValidityChange: (isValid: boolean) => void;
}

export const PersonalInfoStep: React.FC<PersonalInfoStepProps> = ({
  onValidityChange,
}) => {
  const { email, userMetadata } = useAuth();

  const storedData = JSON.parse(localStorage.getItem("cvData") || "{}");

  const {
    control,
    setValue,
    watch,
    formState: { isValid, errors, isDirty, touchedFields },
  } = useForm<PersonalInfo>({
    defaultValues: {
      names: storedData.names || userMetadata.names || "",
      surnames: storedData.surnames || userMetadata.surnames || "",
      dateOfBirth: storedData.dateOfBirth || "",
      dni: storedData.dni || "",
      gender: storedData.gender || "",
      phone: storedData.phone || "",
      email: email || "",
      linkedin: storedData.linkedin || "",
      city: storedData.city || "",
    },
    mode: "onTouched",
  });

  useFormPersist("cvData", {
    watch,
    setValue,
    storage: window.localStorage,
    exclude: ["email"],
  });

  const [photoFile, setPhotoFile] = useState<FileUpload | null>(null);
  const photoInputRef = useRef<HTMLInputElement>(null);
  const [isPhotoTipsModalOpen, setIsPhotoTipsModalOpen] = useState(false);

  useEffect(() => {
    if (isDirty || Object.keys(touchedFields).length > 0) {
      onValidityChange(isValid && Object.keys(errors).length === 0);
    }
  }, [isValid, errors, onValidityChange, isDirty, touchedFields]);

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoFile({ file, preview: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoFile({ file, preview: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const removePhoto = () => {
    setPhotoFile(null);
    if (photoInputRef.current) {
      photoInputRef.current.value = "";
    }
  };

  return (
    <form className={styles.form}>
      <div className={styles.uploadSection}>
        <div className={styles.sectionHeader}>
          <h3>
            Foto de perfil <span className={styles.optional}>(opcional)</span>
          </h3>
          <button
            type="button"
            className={styles.tipsButton}
            onClick={() => setIsPhotoTipsModalOpen(true)}
          >
            Ver consejos para una buena foto 📸
          </button>
        </div>

        <div className={styles.photoContainer}>
          <div
            className={`${styles.photoDropZone} ${
              photoFile ? styles.hasFile : ""
            }`}
            onClick={() => photoInputRef.current?.click()}
            onDrop={handlePhotoDrop}
            onDragOver={handleDragOver}
          >
            <input
              ref={photoInputRef}
              type="file"
              accept="image/*"
              onChange={handlePhotoUpload}
              style={{ display: "none" }}
            />
            {photoFile ? (
              <div className={styles.photoPreview}>
                <img src={photoFile.preview} alt="Preview" />
                <div className={styles.photoActions}>
                  <button
                    className={styles.changePhotoButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      photoInputRef.current?.click();
                    }}
                  >
                    Cambiar
                  </button>
                  <button
                    className={styles.removePhotoButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      removePhoto();
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.uploadIcon}>📸</div>
                <p>Subí tu mejor foto</p>
                <span className={styles.fileTypes}>JPG, PNG</span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label htmlFor="names">
            <span className={styles.icon}>👤</span> Nombres
          </label>
          <Controller
            name="names"
            control={control}
            rules={{
              required: "El nombre es requerido",
              minLength: {
                value: 2,
                message: "El nombre debe tener al menos 2 caracteres",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  {...field}
                  type="text"
                  id="names"
                  placeholder="¿Cómo te llamás?"
                  className={error ? styles.hasError : ""}
                />
                {error && <span className={styles.error}>{error.message}</span>}
              </>
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="surnames">
            <span className={styles.icon}>👤</span> Apellidos
          </label>
          <Controller
            name="surnames"
            control={control}
            rules={{
              required: "El apellido es requerido",
              minLength: {
                value: 2,
                message: "El apellido debe tener al menos 2 caracteres",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  {...field}
                  type="text"
                  id="surnames"
                  placeholder="Tus apellidos"
                  className={error ? styles.hasError : ""}
                />
                {error && <span className={styles.error}>{error.message}</span>}
              </>
            )}
          />
        </div>
      </div>

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label htmlFor="dateOfBirth">
            <span className={styles.icon}>🎂</span> ¿Cuándo naciste?
          </label>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              required: "La fecha de nacimiento es requerida",
              pattern: {
                value: /^\d{2}\/\d{2}\/\d{4}$/,
                message: "Formato inválido. Usa DD/MM/AAAA",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  {...field}
                  type="text"
                  id="dateOfBirth"
                  placeholder="Ingresá tu fecha de nacimiento"
                  maxLength={10}
                  className={error ? styles.hasError : ""}
                  onChange={(e) => field.onChange(formatDate(e.target.value))}
                />
                {error && <span className={styles.error}>{error.message}</span>}
              </>
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="dni">
            <span className={styles.icon}>📋</span> DNI
          </label>
          <Controller
            name="dni"
            control={control}
            rules={{
              required: "El DNI es requerido",
              minLength: {
                value: 7,
                message: "El DNI debe tener al menos 7 dígitos",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  {...field}
                  type="text"
                  id="dni"
                  placeholder="Ingresá tu número de documento"
                  maxLength={12}
                  className={error ? styles.hasError : ""}
                  onChange={(e) => field.onChange(formatDNI(e.target.value))}
                />
                {error && <span className={styles.error}>{error.message}</span>}
              </>
            )}
          />
        </div>
      </div>

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label>
            <span className={styles.icon}>⚡</span> ¿Con qué género te
            identificás?
          </label>
          <Controller
            name="gender"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={styles.genderOptions}>
                <div className={styles.genderRow}>
                  <label className={styles.genderOption}>
                    <input
                      type="radio"
                      value="male"
                      checked={value === "male"}
                      onChange={() => onChange("male")}
                    />
                    <span>Masculino</span>
                  </label>
                  <label className={styles.genderOption}>
                    <input
                      type="radio"
                      value="female"
                      checked={value === "female"}
                      onChange={() => onChange("female")}
                    />
                    <span>Femenino</span>
                  </label>
                </div>
                <label className={styles.genderOption}>
                  <input
                    type="radio"
                    value=""
                    checked={value === ""}
                    onChange={() => onChange("")}
                  />
                  <span>Prefiero no decirlo</span>
                </label>
              </div>
            )}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="phone">
            <span className={styles.icon}>📱</span> Celular
          </label>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "El número de celular es requerido",
              minLength: {
                value: 8,
                message: "El número debe tener al menos 8 dígitos",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  {...field}
                  type="tel"
                  id="phone"
                  placeholder="Tu número de teléfono"
                  className={error ? styles.hasError : ""}
                  onChange={(e) => field.onChange(formatPhone(e.target.value))}
                />
                {error && <span className={styles.error}>{error.message}</span>}
              </>
            )}
          />
        </div>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="email">
          <span className={styles.icon}>📧</span> Email
        </label>
        <Controller
          name="email"
          control={control}
          rules={{ required: "El email es requerido" }}
          render={({ field, fieldState: { error } }) => (
            <>
              <input
                {...field}
                type="email"
                id="email"
                disabled={true}
                className={error ? styles.hasError : ""}
              />
              {error && <span className={styles.error}>{error.message}</span>}
            </>
          )}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="linkedin">
          <span className={styles.icon}>💼</span> LinkedIn &nbsp;
          <span className={styles.optional}>(si tenés, ¡suma puntos!)</span>
        </label>
        <div className={styles.linkedinInputWrapper}>
          <span className={styles.linkedinPrefix}>linkedin.com/in/</span>
          <Controller
            name="linkedin"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <input
                  {...field}
                  type="text"
                  id="linkedin"
                  name="linkedin_username"
                  placeholder="tu-usuario"
                  autoComplete="off"
                  data-lpignore="true"
                />
                {error && <span className={styles.error}>{error.message}</span>}
              </>
            )}
          />
        </div>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="city">
          <span className={styles.icon}>📍</span> ¿Dónde vivís?
        </label>
        <Controller
          name="city"
          control={control}
          rules={{
            required: "La ciudad es requerida",
            minLength: {
              value: 2,
              message: "La ciudad debe tener al menos 2 caracteres",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <input
                {...field}
                type="text"
                id="city"
                placeholder="Ingresá tu ciudad"
                className={error ? styles.hasError : ""}
              />
              {error && <span className={styles.error}>{error.message}</span>}
            </>
          )}
        />
      </div>

      <Modal
        isOpen={isPhotoTipsModalOpen}
        onClose={() => setIsPhotoTipsModalOpen(false)}
        title="Consejos para una buena foto 📸"
      >
        <div className={styles.photoTips}>
          <ul>
            <li>✅ La foto debe ser lo más actual posible.</li>
            <li>
              ❌ No uses fotos que tengas en bares, parques y situaciones
              cotidianas 🍻
            </li>
            <li>
              ✅ El fondo debe ser claro (puede ser una pared) o neutro, que no
              distraiga tu imagen.
            </li>
            <li>
              ❌ Intentá que no sea selfie 🤳. Podés apoyar el celular o cámara
              en algún soporte.
            </li>
            <li>✅ No hace falta que estés serio/a, podes sonreír 😁</li>
            <li>
              ✅ No debe verse borrosa, asegurate que la cámara esté enfocada.
            </li>
            <li>
              ✅ Vestimenta y posición adecuada: no inclines la cabeza para los
              lados, no hagas muecas.
            </li>
          </ul>
        </div>
      </Modal>
    </form>
  );
};
