import React, { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../lib/supabase";

interface AuthContextType {
  isAuthenticated: boolean;
  email: string | null;
  userMetadata: {
    names?: string;
    surnames?: string;
  };
  startEmailAuth: (
    email: string,
    metadata: { names: string; surnames: string }
  ) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [userMetadata, setUserMetadata] = useState<{
    names?: string;
    surnames?: string;
  }>({});

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session?.user) {
        setEmail(session.user.email || null);
        setIsAuthenticated(true);
        setUserMetadata({
          names: session.user.user_metadata.names,
          surnames: session.user.user_metadata.surnames,
        });
      }
    };

    checkSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        setEmail(session.user.email || null);
        setIsAuthenticated(true);
        setUserMetadata({
          names: session.user.user_metadata.names,
          surnames: session.user.user_metadata.surnames,
        });
      } else {
        setEmail(null);
        setIsAuthenticated(false);
        setUserMetadata({});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const startEmailAuth = async (
    email: string,
    metadata: { names: string; surnames: string }
  ) => {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: window.location.origin,
        data: {
          names: metadata.names,
          surnames: metadata.surnames,
        },
      },
    });

    if (error) {
      throw error;
    }

    localStorage.setItem("pendingAuth", email);
  };

  const logout = async () => {
    await supabase.auth.signOut();
    setEmail(null);
    setIsAuthenticated(false);
    localStorage.removeItem("pendingAuth");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        email,
        userMetadata,
        startEmailAuth,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
