import React, { ReactNode } from "react";
import { Tooltip } from "react-tooltip";
import styles from "./Wizard.module.css";
import sharedStyles from "../../styles/shared.module.css";
import { useEffect } from "react";

interface WizardProps {
  currentStep: number;
  totalSteps: number;
  stepTitle: string;
  onNext: () => void;
  onBack: () => void;
  onExit: () => void;
  canGoNext?: boolean;
  canGoBack?: boolean;
  children: ReactNode;
}

export const Wizard: React.FC<WizardProps> = ({
  currentStep,
  totalSteps,
  stepTitle,
  onNext,
  onBack,
  onExit,
  canGoNext = true,
  canGoBack = true,
  children,
}) => {
  useEffect(() => {
    // Remove the beforeunload event listener since we're handling it with our own modal
    return () => {};
  }, []);

  const isLastStep = currentStep === totalSteps - 1;

  const handleNext = () => {
    // Scroll to top before executing onNext
    const content = document.querySelector(`.${styles.wizardContent}`);
    if (content) {
      content.scrollTo({ top: 0, behavior: "smooth" });
    }
    onNext();
  };

  const handleBack = () => {
    // Scroll to top before executing onBack
    const content = document.querySelector(`.${styles.wizardContent}`);
    if (content) {
      content.scrollTo({ top: 0, behavior: "smooth" });
    }
    onBack();
  };

  const handleExitClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onExit();
  };

  return (
    <div className={styles.wizardContainer}>
      {/* Header */}
      <header className={styles.wizardHeader}>
        <button
          className={`${sharedStyles.iconButton} ${styles.exitButton}`}
          onClick={handleExitClick}
        >
          ✕
        </button>
        <h2 className={styles.stepTitle}>{stepTitle}</h2>
        <div className={styles.progressContainer}>
          {Array.from({ length: totalSteps }, (_, i) => (
            <div
              key={i}
              className={`${styles.progressStep} ${
                i < currentStep ? styles.completed : ""
              } ${i === currentStep ? styles.current : ""}`}
            />
          ))}
        </div>
      </header>

      {/* Main Content */}
      <main className={styles.wizardContent}>{children}</main>

      {/* Footer */}
      <footer className={styles.wizardFooter}>
        <div className={styles.buttonsContainer}>
          <button
            className={`${sharedStyles.button} ${sharedStyles.secondary}`}
            onClick={handleBack}
            disabled={!canGoBack}
          >
            Anterior
          </button>
          {!isLastStep ? (
            <>
              <button
                className={`${sharedStyles.button} ${sharedStyles.primary}`}
                onClick={handleNext}
                disabled={!canGoNext}
                data-tooltip-id="next-button-tooltip"
                data-tooltip-content="Completá todos los campos requeridos antes de continuar"
              >
                Siguiente
              </button>
              {!canGoNext && (
                <Tooltip
                  id="next-button-tooltip"
                  place="top"
                  className={styles.tooltip}
                />
              )}
            </>
          ) : (
            <button
              className={`${sharedStyles.button} ${sharedStyles.primary} ${styles.submitButton}`}
              onClick={handleNext}
            >
              Enviar información
            </button>
          )}
        </div>
      </footer>
    </div>
  );
};
