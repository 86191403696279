import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./EducationStep.module.css";

interface Education {
  id: string;
  institution: string;
  degree: string;
  field: string;
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  description?: string;
}

interface EducationStepProps {
  onValidityChange: (isValid: boolean) => void;
}

const allowOnlyNumbers = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const EducationStep: React.FC<EducationStepProps> = ({
  onValidityChange,
}) => {
  const [educationList, setEducationList] = useState<Education[]>([
    {
      id: "1",
      institution: "",
      degree: "",
      field: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
    },
  ]);

  const {
    control,
    formState: { isValid, errors, isDirty, touchedFields },
    unregister,
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    if (isDirty || Object.keys(touchedFields).length > 0) {
      onValidityChange(isValid && Object.keys(errors).length === 0);
    }
  }, [isValid, errors, onValidityChange, isDirty, touchedFields]);

  const handleInputChange = (
    id: string,
    field: keyof Education,
    value: string
  ) => {
    setEducationList((prev) =>
      prev.map((edu) =>
        edu.id === id
          ? {
              ...edu,
              [field]: value,
            }
          : edu
      )
    );
  };

  const addEducation = () => {
    setEducationList((prev) => [
      ...prev,
      {
        id: String(Date.now()),
        institution: "",
        degree: "",
        field: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
      },
    ]);
  };

  const removeEducation = (id: string) => {
    if (educationList.length > 1) {
      unregister([
        `institution-${id}`,
        `degree-${id}`,
        `field-${id}`,
        `startMonth-${id}`,
        `startYear-${id}`,
      ]);
      setEducationList((prev) => prev.filter((edu) => edu.id !== id));
    }
  };

  return (
    <form className={styles.form}>
      {educationList.map((education, index) => (
        <div key={education.id} className={styles.educationCard}>
          <div className={styles.cardHeader}>
            <h3>Formación {index + 1}</h3>
            {educationList.length > 1 && (
              <button
                type="button"
                onClick={() => removeEducation(education.id)}
                className={styles.removeButton}
                aria-label="Eliminar formación"
              >
                ✕
              </button>
            )}
          </div>

          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor={`institution-${education.id}`}>
                <span className={styles.icon}>🏛️</span> Institución
              </label>
              <Controller
                name={`institution-${education.id}`}
                control={control}
                rules={{
                  required: "La institución es requerida",
                  minLength: {
                    value: 2,
                    message: "La institución debe tener al menos 2 caracteres",
                  },
                }}
                defaultValue={education.institution}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      {...field}
                      type="text"
                      id={`institution-${education.id}`}
                      placeholder="Nombre de la institución"
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(
                          education.id,
                          "institution",
                          e.target.value
                        );
                      }}
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                )}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor={`degree-${education.id}`}>
                <span className={styles.icon}>📜</span> Título
              </label>
              <Controller
                name={`degree-${education.id}`}
                control={control}
                rules={{
                  required: "El título es requerido",
                  minLength: {
                    value: 2,
                    message: "El título debe tener al menos 2 caracteres",
                  },
                }}
                defaultValue={education.degree}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <input
                      {...field}
                      type="text"
                      id={`degree-${education.id}`}
                      placeholder="Ej: Licenciatura, Curso, etc."
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(
                          education.id,
                          "degree",
                          e.target.value
                        );
                      }}
                    />
                    {error && (
                      <span className={styles.error}>{error.message}</span>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor={`field-${education.id}`}>
              <span className={styles.icon}>📚</span> Campo de estudio
            </label>
            <Controller
              name={`field-${education.id}`}
              control={control}
              rules={{
                required: "El campo de estudio es requerido",
                minLength: {
                  value: 2,
                  message:
                    "El campo de estudio debe tener al menos 2 caracteres",
                },
              }}
              defaultValue={education.field}
              render={({ field, fieldState: { error } }) => (
                <>
                  <input
                    {...field}
                    type="text"
                    id={`field-${education.id}`}
                    placeholder="Ej: Informática, Marketing, etc."
                    className={error ? styles.hasError : ""}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(education.id, "field", e.target.value);
                    }}
                  />
                  {error && (
                    <span className={styles.error}>{error.message}</span>
                  )}
                </>
              )}
            />
          </div>

          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor={`startDate-${education.id}`}>
                <span className={styles.icon}>📅</span> Fecha de inicio
              </label>
              <div className={styles.dateInputGroup}>
                <Controller
                  name={`startMonth-${education.id}`}
                  control={control}
                  rules={{ required: "El mes es requerido" }}
                  defaultValue={education.startMonth}
                  render={({ field, fieldState: { error } }) => (
                    <select
                      {...field}
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange(
                          education.id,
                          "startMonth",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Mes</option>
                      <option value="01">Enero</option>
                      <option value="02">Febrero</option>
                      <option value="03">Marzo</option>
                      <option value="04">Abril</option>
                      <option value="05">Mayo</option>
                      <option value="06">Junio</option>
                      <option value="07">Julio</option>
                      <option value="08">Agosto</option>
                      <option value="09">Septiembre</option>
                      <option value="10">Octubre</option>
                      <option value="11">Noviembre</option>
                      <option value="12">Diciembre</option>
                    </select>
                  )}
                />
                <Controller
                  name={`startYear-${education.id}`}
                  control={control}
                  rules={{
                    required: "El año es requerido",
                    pattern: {
                      value: /^(19|20)\d{2}$/,
                      message: "Ingresá un año válido (1900-2099)",
                    },
                  }}
                  defaultValue={education.startYear}
                  render={({ field, fieldState: { error } }) => (
                    <input
                      {...field}
                      type="text"
                      placeholder="Año"
                      maxLength={4}
                      className={error ? styles.hasError : ""}
                      onChange={(e) => {
                        const numbersOnly = allowOnlyNumbers(e.target.value);
                        field.onChange(numbersOnly);
                        handleInputChange(
                          education.id,
                          "startYear",
                          numbersOnly
                        );
                      }}
                    />
                  )}
                />
              </div>
              {(errors[`startMonth-${education.id}`] ||
                errors[`startYear-${education.id}`]) && (
                <span className={styles.error}>Fecha de inicio requerida</span>
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor={`endDate-${education.id}`}>
                <span className={styles.icon}>📅</span> Fecha de finalización
              </label>
              <div className={styles.dateInputGroup}>
                <select
                  value={education.endMonth}
                  onChange={(e) =>
                    handleInputChange(education.id, "endMonth", e.target.value)
                  }
                >
                  <option value="">Mes</option>
                  <option value="01">Enero</option>
                  <option value="02">Febrero</option>
                  <option value="03">Marzo</option>
                  <option value="04">Abril</option>
                  <option value="05">Mayo</option>
                  <option value="06">Junio</option>
                  <option value="07">Julio</option>
                  <option value="08">Agosto</option>
                  <option value="09">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
                <input
                  type="text"
                  value={education.endYear}
                  onChange={(e) => {
                    const numbersOnly = allowOnlyNumbers(e.target.value);
                    handleInputChange(education.id, "endYear", numbersOnly);
                  }}
                  placeholder="Año"
                  maxLength={4}
                />
              </div>
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor={`description-${education.id}`}>
              <span className={styles.icon}>📝</span> Descripción{" "}
              <span className={styles.optional}>(opcional)</span>
            </label>
            <textarea
              id={`description-${education.id}`}
              value={education.description}
              onChange={(e) =>
                handleInputChange(education.id, "description", e.target.value)
              }
              placeholder="Logros, actividades destacadas, promedio, etc."
              rows={3}
            />
          </div>
        </div>
      ))}

      <button type="button" onClick={addEducation} className={styles.addButton}>
        + Agregar otra formación
      </button>
    </form>
  );
};
