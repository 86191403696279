import React, { useState } from "react";
import styles from "./ReviewStep.module.css";
import mpLogo from "../../../assets/mp-logo.png";

interface ReviewStepProps {
  onPaymentClick: () => void;
}

export const ReviewStep: React.FC<ReviewStepProps> = ({ onPaymentClick }) => {
  const [jobPlatformOptIn, setJobPlatformOptIn] = useState(false);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText("consultas.curriculum@gmail.com");
    setShowCopiedTooltip(true);
    setTimeout(() => setShowCopiedTooltip(false), 2000);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3>¡Ya casi terminamos! 🎉</h3>

        <div className={styles.optInSection}>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={jobPlatformOptIn}
              onChange={(e) => setJobPlatformOptIn(e.target.checked)}
            />
            <span>
              Quiero que me avisen cuando lancen la plataforma de búsqueda
              laboral
            </span>
          </label>
        </div>

        <div className={styles.pricingOffer}>
          <div className={styles.priceAmount}>$5.000 ARS</div>
          <div className={styles.paymentInfo}>
            <span>Pago único con</span>
            <img src={mpLogo} alt="Mercado Pago" className={styles.mpLogo} />
          </div>
          <button className={styles.paymentButton} onClick={onPaymentClick}>
            Ir a pagar a Mercado Pago
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          </button>
          <div className="secure-badge">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
            </svg>
            <span>Pago seguro verificado</span>
          </div>
        </div>

        <div className={styles.supportSection}>
          <p>
            ¿Tenés dudas? Escribinos a{" "}
            <strong>consultas.curriculum@gmail.com</strong>
            <button
              className={styles.copyButton}
              onClick={handleCopyEmail}
              title="Copiar email"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
              {showCopiedTooltip && (
                <span className={styles.tooltip}>¡Copiado!</span>
              )}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
