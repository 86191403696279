import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { GenericWorkflowCV } from "./components/GenericWorkflowCV/GenericWorkflowCV";

function App() {
  const handleExit = (e?: BeforeUnloadEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // Remove any beforeunload listeners
    window.onbeforeunload = null;
    window.location.href = "/";
  };

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/cv/create/*"
              element={<GenericWorkflowCV mode="create" onExit={handleExit} />}
            />
            <Route
              path="/cv/improve/*"
              element={<GenericWorkflowCV mode="improve" onExit={handleExit} />}
            />
            <Route path="/*" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
